import isEquals from 'lodash.isequal';
import moment from 'moment';
import { getItemFromLocalStorage } from './localstorage';

export const emailRegEx = /^[A-Za-z0-9][-A-Z-a-z0-9.!#$%&'*+-=?^_`{|}~\/]+@([-A-Z-a-z0-9]+\.)+[A-Za-z]{2,5}$/;

export const initModal = {
  type: null,
  content: {},
};
export const initSideModal = {
  type: null,
  content: {},
};

export const capitalize = str => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
};
export function capitalizeFirstLetterOfWords(str) {
  return str.replace(/\b\w/g, char => char.toUpperCase());
}

export const formatText = (text, replaceWith = '-') => {
  if (!text) return '';
  return text?.toLowerCase().replaceAll('_', replaceWith);
};

export const uppercase = str => {
  if (!str) return '';
  return str.toUpperCase();
};

export const lowercase = str => {
  if (!str) return '';
  return str?.toLowerCase();
};

export const handleCopyClick = async url => {
  try {
    await navigator.clipboard.writeText(url);
    return true;
  } catch (error) {
    console.error('Failed to copy:', error);
    return false;
  }
};

export const getUsersPermissions = () => {
  const user = getItemFromLocalStorage('user');
  return user?.api_tokens?.permissions;
};

export const countMatchesInRegex = (regex, str) => {
  return ((str || '').match(regex) || []).length;
};

export const getUserInitials = user => {
  const { forename, surname, name } = user;
  const userName = name?.split(' ') || null;

  return userName
    ? `${userName?.[0]?.charAt(0)?.toUpperCase() || ''}${userName?.[1]?.charAt(0)?.toUpperCase() || ''}`
    : forename || surname
    ? `${forename?.charAt(0)?.toUpperCase() || ''}${surname?.charAt(0)?.toUpperCase() || ''}`
    : '';
};

export const getContactValueByType = (contactTypes, field, field_value, return_field = 'value') => {
  const isForMobile = field_value === 'MOBILE';
  const contactTypeByField = contactTypes?.find(type => type[field] === field_value);
  return contactTypeByField?.[return_field]
    ? isForMobile
      ? `${contactTypeByField?.country_code || ''}${contactTypeByField?.[return_field]}`
      : contactTypeByField?.[return_field]
    : '';
};

export const getCurrencySymbol = currency => {
  switch (currency) {
    case 'USD':
    case 'usd':
      return '$';
    case 'GBP':
    case 'gbp':
      return '£';
    case 'EUR':
    case 'eur':
      return '€';
    default:
      return '$';
  }
};

export const getFormattedDate = (dateInUnix, format = 'DD/MM/YYYY', formatInUTC = true) => {
  if (!formatInUTC) return moment(dateInUnix * 1000).format(format);
  return moment(dateInUnix * 1000)
    .utc()
    .format(format);
};

export const getDateDifference = (date, compareWith = null, unit = 'days') => {
  const compareWithMoment = compareWith ? moment(compareWith * 1000) : moment();

  return date ? compareWithMoment.utc(false).diff(moment(date * 1000).utc(false), unit) : 0;
};

export const onlyNumbers = /^\d+$/;

export const bytesToSize = bytes => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return 'n/a';
  const i = parseInt(Math.floor(Math.log(Math.abs(bytes)) / Math.log(1024)), 10);
  if (i === 0) return `${bytes} ${sizes[i]}`;
  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
};

export const getFormattedNumber = (number, currency) => {
  // Create our number formatter.
  const formatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: currency || 'GBP',
    minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
  });
  return formatter.format(number);
};

export const getFormattedNumberStyle = (
  number,
  style = 'currency',
  minimumFractionDigits = 2,
  maximumFractionDigits = 2,
  ...rest
) => {
  // Create our number formatter.
  const formatter = new Intl.NumberFormat('en-GB', {
    style: style,
    minimumFractionDigits, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits, // (causes 2500.99 to be printed as $2,501)
    ...rest,
  });
  return formatter.format(number);
};

export const getImageThumbnail = mediaData => {
  const { url, media = [] } = mediaData;
  const thumbnail = media ? media?.at(0) : null;
  return thumbnail?.url || url;
};

export const isJson = str => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const isDataChanged = (inputType, newValue, oldValue, confirmAction = () => {}, removeAction = () => {}) => {
  switch (inputType) {
    case 'INPUT': {
      if (newValue !== oldValue) {
        confirmAction();
        return true;
      } else {
        removeAction();
        return false;
      }
    }
    case 'DROPDOWN': {
      if (newValue?.id !== oldValue?.id) {
        confirmAction();
        return true;
      } else {
        removeAction();
        return false;
      }
    }
    case 'ADDRESS': {
      const { line1, line2, city, state, postcode } = newValue;
      const { line1: oldLine1, line2: oldLine2, city: oldCity, state: oldState, postcode: oldPostCode } = oldValue;
      if (
        !isEquals(line1, oldLine1) ||
        !isEquals(line2, oldLine2) ||
        !isEquals(city, oldCity) ||
        !isEquals(state, oldState) ||
        !isEquals(postcode, oldPostCode)
      ) {
        confirmAction();
        return true;
      } else {
        removeAction();
        return false;
      }
    }
    case 'MULTI_DROPDOWN': {
      const newValueIds = newValue.map(v => v.id);
      const oldValueIds = oldValue.map(v => v.id);
      if (!isEquals(newValueIds, oldValueIds)) {
        confirmAction();
        return true;
      } else {
        removeAction();
        return false;
      }
    }
    case 'MULTI_VALUE': {
      if (!isEquals(newValue, oldValue)) {
        confirmAction();
        return true;
      } else {
        removeAction();
        return false;
      }
    }
    default:
      confirmAction();
      return true;
  }
};

export const isUserSassOperator = () => {
  const user = getItemFromLocalStorage('user');
  const userOrganization = user?.organizations?.[0];
  return userOrganization?.organization_type === 'SAAS_OPERATOR';
};

export const getErrorFieldJoined = (errorObject, getValue = () => {}, joinBy = ', ') => {
  return Object.entries(errorObject)
    .filter(([_, value]) => value)
    .map(([key, value]) => getValue(key, value))
    .join(joinBy);
};

export const getErrorDescription = (error, defaultErrorText = '') => {
  if (!error) return '';
  return error?.response?.data?.error_description || defaultErrorText;
};

export const getProductPrice = product => {
  const { pricing } = product || {};
  const { prices } = pricing?.[0] || {};
  const { price, currency } = prices?.[0] || {};

  return getFormattedNumber(price || 0, currency || 'GBP');
};

export const getRoofDetailsFromManualOrImported = (
  roofData,
  key,
  fromManualOnly = false,
  fromImportOnly = false,
  format = true,
) => {
  const { manual, imported } = roofData || {};
  const area = manual?.area ?? imported?.area;
  const boundary = manual?.boundary ?? imported?.boundary;
  const panels_count = manual?.panels_count ?? imported?.panels_count;
  const yearly_energy = manual?.yearly_energy ?? imported?.yearly_energy;
  const azimuth = manual?.azimuth ?? imported?.azimuth;
  const pitch = manual?.pitch ?? imported?.pitch;

  let value = 0.0;

  const segmentDetails = {
    area: area || 0,
    boundary: boundary || [],
    panels_count: panels_count || 0,
    yearly_energy: yearly_energy || 0,
    azimuth: azimuth || 0,
    pitch: pitch || 0,
  };

  if (fromManualOnly) {
    value = manual ? manual[key] : key === 'boundary' ? [] : 0;
  } else if (fromImportOnly) {
    value = imported ? imported[key] : key === 'boundary' ? [] : 0;
  } else {
    value = segmentDetails[key];
  }
  if (format) {
    return getFormattedNumberStyle(value, 'decimal');
  }
  return value;
};

export const getPolygonCenter = polygonPaths => {
  // put all latitudes and longitudes in arrays
  const latitudes = polygonPaths.map(path => path.lat);
  const longitudes = polygonPaths.map(path => path.lng);

  // sort the arrays low to high
  latitudes.sort();
  longitudes.sort();

  // get the min and max of each
  const lowX = latitudes[0];
  const highX = latitudes[latitudes.length - 1];
  const lowy = longitudes[0];
  const highy = longitudes[latitudes.length - 1];

  // center of the polygon is the starting point plus the midpoint
  const centerX = lowX + (highX - lowX) / 2;
  const centerY = lowy + (highy - lowy) / 2;

  return { lat: centerX, lng: centerY };
};

export const rotatePoint = (point, origin, angle) => {
  const angleRad = (angle * Math.PI) / 180.0;
  return {
    x: Math.cos(angleRad) * (point.x - origin.x) - Math.sin(angleRad) * (point.y - origin.y) + origin.x,
    y: Math.sin(angleRad) * (point.x - origin.x) + Math.cos(angleRad) * (point.y - origin.y) + origin.y,
  };
};
