import { configureStore } from '@reduxjs/toolkit';
import toastActionSlice from './features/ToastActionSlice';
import integrationsSlice from './features/integrationsSlice';
import toastSlice from './features/toastSlice';
import userSlice from './features/userSlice';
import reloadSlice from './features/reloadSlice';

export default configureStore({
  reducer: {
    reload: reloadSlice,
    toast: toastSlice,
    toastAction: toastActionSlice,
    user: userSlice,
    integrations: integrationsSlice,
  },
  devTools: process.env.REACT_APP_STAGE === 'development',
});
