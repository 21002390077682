import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { OrganisationContext } from '../../context/organisationContext';
import { getUsersPermissions } from '../../helpers/utils';
import { addToast } from '../../store/features/toastSlice';
import { MainContentWrapper } from '../../styles/pages/main-content.styled';
import NavBar from '../../components/main-layout/navbar/navbar';
import {
  getIntegrationList,
  getDeals,
  setImported,
  addImported,
  getPipelines,
  getProjectList,
  getCampaignsList,
  getJobTypeList,
  getWorkflowList,
  getStagesListData,
} from '../../store/features/integrationsSlice';
import Dropdown from '../../components/common/select-dropdown';
import InputSearch from '../../components/common/input-search';
import Checkbox from '../../components/common/checkbox';
import Button from '../../components/common/button/button';
import { ReactComponent as Loader } from '@assets/images/loading.svg';
import { ReactComponent as CheckIcon } from '../../assets/icons/check-done-blue.svg';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loading from '../../assets/images/loading.svg';
import { ReactComponent as CrossIcon } from '../../assets/icons/property/cross_icon.svg';
import Stage from '../../components/stage';
import InfiniteScrollV2 from '../../components/common/infinite-scroll-v2';
import { getToken } from '../../store/services';
import api from '../../store/services';

const Main = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { toasts } = useSelector(state => state.toast);
  const { imported } = useSelector(state => state.integrations);
  const [integrations, setIntegrations] = useState([]);
  const [integration, setIntegration] = useState();
  const [deals, setDeals] = useState({});
  const [stage, setStage] = useState();
  const [selected, setSelected] = useState([]);
  const [all, setAll] = useState(false);
  const [importing, setImporting] = useState(false);
  const [pipelines, setPipelines] = useState([]);
  const [jobTypes, setJobTypes] = useState([]);
  const [projects, setProjects] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [jobType, setJobType] = useState();
  const [project, setProject] = useState();
  const [campaign, setCampaign] = useState();
  const [workflows, setWorkflows] = useState([]);
  const [workflow, setWorkflow] = useState();
  const [pipeline, setPipeline] = useState();
  const [pipelineStage, setPipelineStage] = useState();
  const [hasMore, setHasMore] = useState(false);
  const [stages, setStages] = useState([]);

  const [modal, setModal] = useState({
    type: null,
    content: {},
  });
  const [insideModal, setInsideModal] = useState({
    type: null,
    content: {},
  });
  const [sideModal, setSideModal] = useState({
    type: null,
    content: {},
  });
  const permissions = getUsersPermissions();

  const hasPermission = permission => {
    return permissions.includes(permission);
  };

  const hasMultiplePermission = permissionList => {
    return (
      !permissionList ||
      !!(permissionList.length > 0 && permissionList.find(permission => permissions.includes(permission)))
    );
  };

  const checkPermission = (action, permission) => {
    if (!permission || permissions.includes(permission)) {
      if (action) action();
    } else
      !toasts.find(t => t.id === 'PERMISSION_ERROR') &&
        dispatch(
          addToast({
            id: 'PERMISSION_ERROR',
            error: true,
            text: t('YOU_DO_NOT_HAVE_PERMISSION_TO_PERFORM_THIS_ACTION'),
          }),
        );
  };

  const checkMultiplePermission = (action, permissionList) => {
    if (
      !permissionList ||
      (permissionList.length > 0 && permissionList.find(permission => permissions.includes(permission)))
    ) {
      if (action) action();
    } else
      !toasts.find(t => t.id === 'PERMISSION_ERROR') &&
        dispatch(
          addToast({
            id: 'PERMISSION_ERROR',
            error: true,
            text: t('YOU_DO_NOT_HAVE_PERMISSION_TO_PERFORM_THIS_ACTION'),
          }),
        );
  };

  const fetchIntegrations = () => {
    dispatch(getIntegrationList({ params: { connector_class: 'e584b890-68a3-497c-b2cb-1e2adeaf9d66' } }))
      .then(data => {
        setIntegrations(data);
      })
      .catch(() => {
        setIntegrations([]);
      });
  };

  useEffect(() => {
    fetchIntegrations();
  }, []);

  useEffect(() => {
    if (integration && integration.connector.tech_provider === 'HUBSPOT' && pipelineStage)
      dispatch(getDeals({ id: integration.id, stage: pipelineStage?.id })).then(data => {
        setDeals(data);
        setHasMore(data?.response?.paging ? true : false);
      });
    else setDeals({});
  }, [integration, pipelineStage]);

  const fetchMoreDeals = () => {
    dispatch(
      getDeals({ id: integration.id, stage: pipelineStage?.id, after: deals?.response?.paging?.next?.after }),
    ).then(data => {
      setDeals({
        ...deals,
        response: {
          results: [...deals?.response?.results, ...data?.response?.results],
          paging: data?.response?.paging,
          total: data?.response?.total,
        },
      });
      setHasMore(data?.response?.paging ? true : false);
    });
  };

  const importDeals = async () => {
    setImporting(true);
    const token = await getToken();
    for (var s of selected) {
      await importDeal({
        id: integration.id,
        dealId: s.id,
        jobTypeId: jobType?.id,
        projectId: project?.id,
        campaignId: campaign?.id,
        workflowId: workflow?.id,
        stageId: stage?.id,
        token: `Bearer ${token}`,
      });
    }

    // setImporting(false);
  };
  const importDeal = async ({ id, dealId, jobTypeId, projectId, campaignId, workflowId, stageId, token }) => {
    try {
      const { data } = await api.post(`/integrations/${id}/action/DEAL_IMPORT/execute`, {
        action_parameters: {
          DEAL_ID: dealId,
          JOB_TYPE_ID: jobTypeId,
          PROJECT_ID: projectId,
          CAMPAIGN_ID: campaignId,
          WORKFLOW_ID: workflowId,
          STAGE_ID: stageId,
          ZERVIO_TOKEN: token,
        },
      });
      dispatch(addImported({ id: dealId }));
    } catch (e) {
      dispatch(
        addImported({
          id: dealId,
          error: `${e.response?.data?.error_description || ''} - ${e.response?.data?.response || ''}`,
        }),
      );
    }
  };

  useEffect(() => {
    if (integration && integration.connector.tech_provider === 'HUBSPOT')
      dispatch(getPipelines({ id: integration.id }))
        .then(data => {
          setPipelines(data);
        })
        .catch(() => {
          setPipelines([]);
        });
  }, [integration]);

  useEffect(() => {
    dispatch(getProjectList({ params: {} }))
      .then(data => {
        setProjects(data);
      })
      .catch(() => {
        setProjects([]);
      });

    dispatch(getCampaignsList({ params: {} }))
      .then(data => {
        setCampaigns(data);
      })
      .catch(() => {
        setCampaigns([]);
      });

    dispatch(getJobTypeList({ params: {} }))
      .then(data => {
        setJobTypes(data);
      })
      .catch(() => {
        setJobTypes([]);
      });

    dispatch(getWorkflowList({ params: {} }))
      .then(data => {
        setWorkflows(data);
      })
      .catch(() => {
        setWorkflows([]);
      });
  }, []);

  useEffect(() => {
    if (workflow)
      dispatch(getStagesListData({ workflowId: workflow.id, params: {} }))
        .then(data => {
          setStages(data);
        })
        .catch(() => {
          setStages([]);
        });
  }, [workflow]);

  return (
    <MainContentWrapper>
      <OrganisationContext.Provider
        value={{
          modal,
          setModal,
          insideModal,
          setInsideModal,
          checkPermission,
          hasPermission,
          hasMultiplePermission,
          checkMultiplePermission,
          sideModal,
          setSideModal,
        }}>
        <div className="flex-column flex-1">
          <NavBar />
          <div className="flex-column mxy-6">
            <Dropdown
              onChange={option => setIntegration(option)}
              options={integrations}
              getOptionLabel={option => {
                const { connector, name } = option;
                return connector.tech_provider ? `${connector.tech_provider} - ${name}` : name;
              }}
              getOptionValue={option => option.id}
              placeholder="Select integration"
              value={integration || null}
            />
          </div>
          <div className="flex items-center px-6 mb-6">
            <Dropdown
              onChange={option => setProject(option)}
              options={projects}
              getOptionValue={option => option.id}
              placeholder="Select project"
              value={project || null}
              isClearable={true}
              getOptionLabel={option => {
                const { name } = option;
                return name;
              }}
            />
            <Dropdown
              className="ml-6"
              onChange={option => setCampaign(option)}
              options={campaigns}
              getOptionValue={option => option.id}
              placeholder="Select campaign"
              value={campaign || null}
              getOptionLabel={option => {
                const { name } = option;
                return name;
              }}
              isClearable={true}
            />
            <Dropdown
              className="ml-6"
              onChange={option => setJobType(option)}
              options={jobTypes}
              getOptionValue={option => option.id}
              placeholder="Select job type"
              value={jobType || null}
              getOptionLabel={option => {
                const { name } = option;
                return name;
              }}
              isClearable={true}
            />
          </div>
          {integration && integration.connector.tech_provider === 'HUBSPOT' && (
            <div className="flex-column border-top pt-6">
              <div className="flex items-center px-6 mb-6">
                <Dropdown
                  onChange={option => {
                    setPipeline(option);
                    setPipelineStage(null);
                  }}
                  className="flex-1"
                  options={pipelines?.response || []}
                  getOptionValue={option => option.id}
                  placeholder="Select pipeline"
                  value={pipeline || null}
                  isClearable={true}
                  getOptionLabel={option => {
                    const { name } = option;
                    return name;
                  }}
                />
                <Dropdown
                  className="flex-1 ml-6"
                  onChange={option => {
                    setWorkflow(option);
                    setStage(null);
                  }}
                  options={workflows}
                  getOptionValue={option => option.id}
                  placeholder="Select workflow"
                  value={workflow || null}
                  getOptionLabel={option => {
                    const { name } = option;
                    return name;
                  }}
                  isClearable={true}
                />

                <div />
              </div>
              <div className="flex items-center px-6 mb-6">
                <Dropdown
                  className="flex-1"
                  onChange={option => setPipelineStage(option)}
                  options={pipeline?.stages || []}
                  getOptionValue={option => option.id}
                  placeholder="Select pipeline stage"
                  value={pipelineStage || null}
                  getOptionLabel={option => {
                    const { name } = option;
                    return name;
                  }}
                  isClearable={true}
                />
                <Dropdown
                  className="ml-6 flex-1"
                  onChange={option => setStage(option)}
                  options={stages}
                  getOptionValue={option => option.id}
                  placeholder="Select stage"
                  value={stage || null}
                  getOptionLabel={option => {
                    const { name } = option;
                    return name;
                  }}
                  isClearable={true}
                />
                <div />
              </div>
            </div>
          )}
          {/* <div className="flex items-center px-6 mb-6">
            <Dropdown
              onChange={option => setPipeline(option)}
              options={pipelines?.response}
              getOptionValue={option => option.id}
              placeholder="Select pipeline"
              value={pipeline || null}
              getOptionLabel={option => {
                const { name } = option;
                return name;
              }}
            />
          </div> */}
          <div className="flex-column px-6 deal-list mb-6">
            <div className="flex flex-1 flex-center justify-between">
              {/* <div className="flex flex-1">
                {deals?.response && (
                  <InputSearch
                    placeholder={'Deal stage'}
                    className="input-search-left mb-4 flex-1"
                    value={stage}
                    onChange={setStage}
                  />
                )}
              </div> */}
            </div>
            <div className="flex">
              <div className={`flex-column overflow-hidden flex-1 ${importing && 'o05'}`}>
                {deals?.response && (
                  <div className="flex items-center justify-between" style={{ height: '50px' }}>
                    <div className="flex py-2">
                      <Checkbox
                        onChange={() => {
                          if (importing) return;
                          if (all) {
                            setAll(false);
                            setSelected([]);
                          } else {
                            setAll(true);
                            setSelected(deals?.response?.results);
                          }
                        }}
                        checked={all}
                        is_checked_done={true}
                      />
                      <label className="flex-1 inter-400-text natural-900-text option-text one-line ml-2">All</label>
                    </div>
                    {selected && selected.length > 0 && !importing && (
                      <Button
                        label="Import"
                        className="primary mx-6 specified-width"
                        size="average"
                        borderRadius="100px"
                        onClick={importDeals}
                        width="200px"
                      />
                    )}
                  </div>
                )}
                {deals?.response && (
                  <div className={'flex-column flex-1 overflow-scroll'} id="scrollableDiv">
                    <InfiniteScroll
                      className={'flex-column flex-1'}
                      dataLength={deals.response?.results?.length}
                      next={fetchMoreDeals}
                      hasMore={hasMore}
                      height={800}
                      scrollThreshold={0.9}
                      loader={
                        <div className="item flex items-center justify-center my-1">
                          <img alt="loading" height="24px" src={Loading} />
                        </div>
                      }
                      scrollableTarget={'scrollableDiv'}>
                      {deals.response?.results?.map(deal => (
                        <div
                          key={deal.id}
                          className="flex py-2 cursor"
                          onClick={() => {
                            if (importing) return;
                            setAll(false);
                            selected.find(s => s.id === deal.id)
                              ? setSelected(selected.filter(s => s.id !== deal.id))
                              : setSelected([...selected, deal]);
                          }}>
                          <Checkbox
                            onChange={() => {
                              if (importing) return;
                              setAll(false);
                              selected.find(s => s.id === deal.id)
                                ? setSelected(selected.filter(s => s.id !== deal.id))
                                : setSelected([...selected, deal]);
                            }}
                            checked={selected.find(s => s.id === deal.id)}
                            is_checked_done={true}
                          />
                          <label className="flex-1 inter-400-text natural-900-text option-text one-line ml-2">
                            {deal.name}
                          </label>
                        </div>
                      ))}
                    </InfiniteScroll>
                  </div>
                )}
              </div>
              {(importing || imported.length > 0) && (
                <div className="flex-column flex-1 mb-6">
                  <label className="inter-400-text natural-900-text option-text one-line ml-2 mb-2 font-20">
                    Importing
                  </label>
                  {selected?.map(d => (
                    <div key={d.id} className="flex items-center py-2 cursor">
                      {imported.find(i => i.id === d.id) ? (
                        imported.find(i => i.id === d.id)?.error ? (
                          <CrossIcon width={20} height={20} />
                        ) : (
                          <CheckIcon width={20} height={20} />
                        )
                      ) : (
                        <Loader width={20} height={20} />
                      )}
                      <div className="flex-column ml-2">
                        <label className="inter-400-text natural-900-text option-text one-line">{d.name}</label>
                        {imported.find(i => i.id === d.id)?.error && (
                          <label className="inter-400-text natural-900-text option-text red-text mt-2">
                            {imported.find(i => i.id === d.id)?.error}
                          </label>
                        )}
                      </div>
                    </div>
                  ))}
                  <Button
                    label="Done"
                    className="primary mt-4 specified-width "
                    size="average"
                    borderRadius="100px"
                    onClick={() => {
                      setImporting(false);
                      dispatch(setImported([]));
                      setSelected([]);
                    }}
                    width="200px"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </OrganisationContext.Provider>
    </MainContentWrapper>
  );
};

export default Main;
