import classNames from 'classnames';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as MasqueradeIcon } from '../../../assets/icons/masquerade.svg';
import { ReactComponent as Logout } from '../../../assets/icons/property/logout.svg';
import { ReactComponent as Usericon } from '../../../assets/icons/user-outline.svg';
import Logo from '../../../assets/images/logo.svg';
import { OrganisationContext } from '../../../context/organisationContext';
import { setItemInLocalStorage } from '../../../helpers/localstorage';
import { getUserInitials } from '../../../helpers/utils';
import { setUser } from '../../../store/features/userSlice';
import { NavBarWrapper } from '../../../styles/components/main-layout/navbar.styles';
import ProfileMenuDropdown from '../../common/menu/profile-menu-dropdown';

const NavBar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector(state => state.user);
  const { setSideModal } = useContext(OrganisationContext);

  const logout = () => {
    localStorage.removeItem('user');
    navigate('/login', { replace: true });
  };

  const onExitMasquerade = () => {
    const api_tokens = user.original_api_tokens;
    const organizations = user.original_organizations;
    const updatedUser = {
      ...user,
      api_tokens: { ...api_tokens },
      organizations: organizations,
      accountDetails: null,
      isAccessAccount: false,
      original_api_tokens: null,
      masquarage_organizations: null,
      selectedAccount: null,
    };
    setItemInLocalStorage('user', updatedUser);
    dispatch(setUser(updatedUser));
    navigate('/properties', { replace: true });
  };

  const onEditUserProfile = user => {
    setSideModal({
      type: 'edit-user-profile',
      content: {
        user_id: user?.id,
        fromMyProfile: true,
        onSuccess: () => {},
      },
    });
  };

  const masqueradeMenuItem = user.isAccessAccount
    ? {
        name: t('EXIT_MASQUERADE'),
        icon: MasqueradeIcon,
        withIcon: true,
        onClick: () => onExitMasquerade(),
      }
    : null;

  return (
    <NavBarWrapper className="flex justify-between w-full px-6 py-4">
      <div className="flex">
        <div className="flex items-center logo-container">
          <img className="logo" src={Logo} alt="logo" />
        </div>
      </div>
      <div className="flex items-center">
        {/* <IconContainer
          iconContainerClassname="ml-4 nav-icon-wrapper"
          iconHeight={18}
          iconWidth={18}
          Icon={SearchIcon}
          backgroundColor="natural_150"
          iconColor="natural_500"
        />

        <IconContainer
          iconContainerClassname="ml-4 nav-icon-wrapper"
          iconHeight={18}
          iconWidth={18}
          Icon={NotificationIcon}
          backgroundColor="natural_150"
          iconColor="natural_500"
        /> */}

        <ProfileMenuDropdown
          menuList={[
            {
              name: t('LOG_OUT'),
              icon: Logout,
              withIcon: true,
              onClick: () => logout(),
            },
            masqueradeMenuItem,
          ].filter(Boolean)}
          title={getUserInitials(user?.user)}
        />
      </div>
    </NavBarWrapper>
  );
};

export const Label = styled.label`
  position: relative;

  &::after {
    position: absolute;
    content: '';
    width: 0;
    height: 1px;
    bottom: -6px;
    left: 0;
    border-radius: 2px;
    transition: 400ms ease;
  }

  &:hover {
    &::after {
      width: 100%;
    }
  }

  &.underline-light {
    &::after {
      background: ${({ theme }) => theme.natural_400};
    }
  }
  &.underline-dark {
    &::after {
      background: ${({ theme }) => theme.natural_900};
    }
  }
`;

export default NavBar;
