import styled from 'styled-components';

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  color: ${props => props.color};
  background: ${props => props.bgColor};
  height: ${props => props.height};
  border: ${props => props.borderWidth} solid ${props => props.borderColor};
  border-radius: ${props => props.borderRadius};
  cursor: pointer;

  * {
    cursor: pointer;
  }

  &:active {
    opacity: 0.8;
  }

  label {
    color: ${props => props.color};
    font-size: ${props => props.fontSize};
  }

  &.primary {
    background: ${({ theme }) => theme.primaryBlue};
    border: 1px solid ${({ theme }) => theme.primaryBlue};

    label {
      color: #ffffff;
    }
  }

  &.default {
    background: ${({ theme }) => theme.white};
    border: 1px solid ${({ theme }) => theme.natural_200};
    label {
      color: ${({ theme }) => theme.natural_700};
    }
  }

  &.secondary {
    background: ${({ theme }) => theme.secondaryBlue};
    border: 1px solid ${({ theme }) => theme.secondaryBlue};

    label {
      color: ${({ theme }) => theme.primaryBlue};
    }
  }

  &.negative {
    background: ${({ theme }) => theme.error_500};
    border: 1px solid ${({ theme }) => theme.error_500};

    label {
      color: #ffffff;
    }
  }

  &.primary-white {
    background: #ffffff;
    border: 1px solid ${({ theme }) => theme.primaryBlue};

    label {
      color: ${({ theme }) => theme.primaryBlue};
    }
  }

  &.primary-blue {
    background: ${({ theme }) => theme.primary_50};
    border: 1px solid ${({ theme }) => theme.primary_50};

    label {
      color: ${({ theme }) => theme.primaryBlue};
    }
  }

  &.primary-grey {
    background: #ffffff;
    border: 1px solid ${({ theme }) => theme.natural_200};

    label {
      color: ${({ theme }) => theme.natural_700};
    }
  }

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
    cursor: not-allowed !important;

    svg {
      * {
        stroke: #ffffff;
      }
    }
  }

  &.specified-width {
    width: ${({ width }) => width};
  }
`;

export default ButtonWrapper;
