import { createSlice } from '@reduxjs/toolkit';
import api from '../services';

export const integrationsSlice = createSlice({
  name: 'integrations',
  initialState: {
    integrationList: [],
    imported: [],
  },
  reducers: {
    setIntegrationList: (state, { payload }) => {
      state.integrationList = payload;
    },
    addImported: (state, { payload }) => {
      state.imported = [...state.imported, payload];
    },
    setImported: (state, { payload }) => {
      state.imported = payload;
    },
  },
});

export const getIntegrationList =
  ({ params, storeData = false }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/integrations`, { params: params });
      storeData && dispatch(setIntegrationList(data));
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getDeals =
  ({ id, stage, after }) =>
  async dispatch => {
    try {
      const { data } = await api.post(`/integrations/${id}/action/DEALS_LIST/execute`, {
        action_parameters: {
          AFTER: after,
          LIMIT: 100,
          DEAL_STAGE: stage,
        },
      });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const importDeal =
  ({ id, dealId, jobTypeId, projectId, campaignId, workflowId, stageId, token }) =>
  async dispatch => {
    try {
      const { data } = await api.post(`/integrations/${id}/action/DEAL_IMPORT/execute`, {
        action_parameters: {
          DEAL_ID: dealId,
          JOB_TYPE_ID: jobTypeId,
          PROJECT_ID: projectId,
          CAMPAIGN_ID: campaignId,
          WORKFLOW_ID: workflowId,
          STAGE_ID: stageId,
          ZERVIO_TOKEN: token,
        },
      });
      dispatch(addImported({ id: dealId }));
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getPipelines =
  ({ id }) =>
  async dispatch => {
    try {
      const { data } = await api.post(`/integrations/${id}/action/PIPELINES_LIST/execute`, {
        action_parameters: {},
      });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getProjectList =
  ({ params }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/projects`, { params: params });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getCampaignsList =
  ({ params }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/campaigns`, { params: params });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getJobTypeList =
  ({ params }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/job_types`, { params: params });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getWorkflowList =
  ({ params }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/workflows`, { params: params });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getStagesListData =
  ({ params, workflowId }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/workflows/${workflowId}/stages`, { params: params });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const { setIntegrationList, addImported, setImported } = integrationsSlice.actions;
export default integrationsSlice.reducer;
