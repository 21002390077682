import React, { Fragment } from 'react';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import Login from '../pages/login';
import Main from '../pages/main/main';
import history from './history';
import { PrivateRoute } from './privateRoute';

const routes = createBrowserRouter([
  {
    path: 'login',
    element: <Login />,
  },
  {
    element: <PrivateRoute />,
    children: [
      {
        path: '*',
        element: <Main />,
      },
    ],
  },
]);

export const Router = () => {
  return (
    <Fragment>
      <RouterProvider history={history} router={routes} />
    </Fragment>
  );
};
