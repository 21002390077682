import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { ReactComponent as SearchIcon } from '../../../assets/images/search.svg';

const InputSearch = ({ value, onChange, placeholder, className, ...rest }) => {
  return (
    <InputSearchWrapper className={`flex relative ${className}`}>
      <SearchIcon className="absolute search-icon natural-500-text" height={16} width={16} />
      <input
        className={classNames('input w-full', { 'value-available': value })}
        value={value}
        onChange={({ target }) => onChange(target.value)}
        placeholder={placeholder}
        {...rest}
      />
    </InputSearchWrapper>
  );
};

const InputSearchWrapper = styled('div')`
  .search-icon {
    height: 100%;
    margin: 0 12px;
  }

  .input {
    height: 40px;
    font-size: 14px;
    padding-left: 32px;
    border-radius: 12px;
    border-radius: 100px;
    background-color: ${({ theme }) => theme.natural_150};
    border-color: ${({ theme }) => theme.natural_150};

    ::placeholder {
      color: ${({ theme }) => theme.natural_500};
      opacity: 1;
    }

    :focus {
      box-shadow: 0px 0px 0px 4px #d3eafe;
      background-color: #fff;
    }
  }

  .value-available {
    background-color: #fff;
  }
`;

export default InputSearch;
