import styled from 'styled-components';

export const NavBarWrapper = styled.div`
  height: fit-content;
  background: ${({ theme }) => theme.navBackground};
  border-bottom: 1px solid #e3eef3;

  .logo-container {
    min-width: 208px;
  }

  .logo {
    height: 24px;
    width: 140px;
  }

  .global-search-input {
    .search-icon {
      width: 16px;
    }
    .input {
      width: 168px;
    }
  }

  .nav-icon-wrapper {
    width: 40px;
    height: 100%;
    min-height: 40px;
    border-radius: 100px;
  }
  .profile-detail-user {
    width: 180px !important;
  }

  .user-logo-wrapper {
    background: #171717;
  }
  .active-logo {
    border-radius: 100px;
    box-shadow: 0px 0px 0px 6px #e0e0e0;
  }
`;
